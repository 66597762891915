:global(#app) {
  /* .logo {
    margin-bottom: 16px;
  } */

  .wrapper {
    border: none;
    box-shadow: none;
  }
}
