:global(#app) {
  .users {
    margin-top: 8px;
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }
}
